import Vue from 'vue';
import tippy from 'tippy.js';
import {getQueryParam, updateQueryParam} from '../lib/queryParam';

if (document.querySelector('#calculator-vue-app')) {
    var form_type = getQueryParam('type', window.location);
    var form_tax_equity_investment = getQueryParam('tax_equity_investment', window.location)?.replace(/%2C/g, ",");

    var calculator_app = new Vue({
        el: "#calculator-vue-app",
        data: {
            tax_equity_investment: form_tax_equity_investment || null,
            type: form_type ? form_type : "renewable_energy",
            magic_numbers: {
                renewable_energy: {
                    total_investment_multiplier: 3.3042558,
                    solar_farm_cost: 1250000,
                    carbon_factor: 900,
                    acdc_multiplier: 0.75,
                    cellphones: 7.84,
                    trees: 0.06,
                    electricity: 5.906,
                    gasoline: 8.887,
                    cars: 4.63,
                    jobs: 4
                },
                historic_rehabilitation: {
                    total_rehab_multiplier: 0.9,
                    price: 0.94,
                    jobs: 0.000017075521726924,
                    income: 0.710406463486914,
                    gdp: 0.965249399507128,
                    output: 1.89361137972986,
                    tax: 0.250678478959354
                },
                affordable_housing: {
                    total_investment_multiplier: 2.666666667,
                    cost_per_unit: 150000,
                    jobs: 8.21,
                    income: 507000,
                    tax: 97450
                }
            }
        },
        
        methods: {
            calculateAbatement: function(years) {
                var total = this.renewable_energy_annual_abatement;
    
                for (var i = 1; i <= years - 1; i++) {
                    total += this.renewable_energy_annual_abatement * (Math.pow(0.995, i));
                }
    
                return total;
            },
    
            setupTooltips: function() {
                setTimeout(function() {
                    tippy('[data-tippy-content]', {
                        theme: "dark"
                    });
                }, 100);
            },

            updateQueryString: function() {
                var params_url = updateQueryParam('type', this.type, window.location.pathname);
                params_url = updateQueryParam('tax_equity_investment', this.tax_equity_investment_clean, params_url);
                params_url += '#calculator-results';
                console.log(params_url);
                history.pushState(null, '', params_url);
            },

            changeType: function() {
                this.setupTooltips();
                this.updateQueryString();
            }
        },
        
        computed: {
            tax_equity_investment_clean: function() {
                return this.tax_equity_investment ? this.tax_equity_investment.toString().replace(/\D/g,'') : 0;
            },
            
            // Renewable Energy
            
            renewable_energy_solar_installation: function() {
                return this.tax_equity_investment_clean * this.magic_numbers.renewable_energy.total_investment_multiplier;
            },
    
            renewable_energy_clean_power_capacity: function() {
                return (this.renewable_energy_solar_installation / this.magic_numbers.renewable_energy.solar_farm_cost);
            },
    
            renewable_energy_ac_capacity: function() {
                return this.renewable_energy_clean_power_capacity * this.magic_numbers.renewable_energy.acdc_multiplier;
            },
    
            renewable_energy_annual_abatement: function() {
                return this.magic_numbers.renewable_energy.carbon_factor * this.renewable_energy_ac_capacity * 2920 * 0.000453592;
            },
    
            renewable_energy_hold_period_abatement: function() {
                return this.calculateAbatement(6);
            },
    
            renewable_energy_minimum_lifetime_abatement: function() {
                return this.calculateAbatement(35);
            },
    
            renewable_energy_maximum_lifetime_abatement: function() {
                return this.calculateAbatement(50);
            },
    
            renewable_energy_maximum_lifetime_abatement_40: function() {
                return this.calculateAbatement(40);
            },
    
            renewable_energy_cellphones_charged: function() {
                return this.renewable_energy_maximum_lifetime_abatement_40 / this.magic_numbers.renewable_energy.cellphones * 1000000;
            },
    
            renewable_energy_trees_grown: function() {
                return this.renewable_energy_maximum_lifetime_abatement_40 / this.magic_numbers.renewable_energy.trees;
            },
    
            renewable_energy_home_electricity_eliminated: function() {
                return this.renewable_energy_maximum_lifetime_abatement_40 / this.magic_numbers.renewable_energy.electricity;
            },
    
            renewable_energy_gasoline_gallons_reduced: function() {
                return this.renewable_energy_maximum_lifetime_abatement_40 / this.magic_numbers.renewable_energy.gasoline * 1000;
            },
    
            renewable_energy_cars_removed: function() {
                return this.renewable_energy_maximum_lifetime_abatement_40 / this.magic_numbers.renewable_energy.cars;
            },
    
            renewable_energy_jobs_created: function() {
                return this.renewable_energy_clean_power_capacity * this.magic_numbers.renewable_energy.jobs;
            },
            
            // Historic Rehabilitation
            
            historic_rehabilitation_tax_credits: function() {
                return this.tax_equity_investment_clean / this.magic_numbers.historic_rehabilitation.price;
            },
            
            historic_rehabilitation_qre: function() {
                return this.historic_rehabilitation_tax_credits * 5;
            },
            
            historic_rehabilitation_total_rehab_cost: function() {
                return this.historic_rehabilitation_qre / this.magic_numbers.historic_rehabilitation.total_rehab_multiplier;
            },
            
            historic_rehabilitation_jobs_created: function() {
                return this.historic_rehabilitation_total_rehab_cost * this.magic_numbers.historic_rehabilitation.jobs;
            },
            
            historic_rehabilitation_income_generated: function() {
                return this.historic_rehabilitation_total_rehab_cost * this.magic_numbers.historic_rehabilitation.income;
            },
            
            historic_rehabilitation_gdp_added: function() {
                return this.historic_rehabilitation_total_rehab_cost * this.magic_numbers.historic_rehabilitation.gdp;
            },
            
            historic_rehabilitation_output_generated: function() {
                return this.historic_rehabilitation_total_rehab_cost * this.magic_numbers.historic_rehabilitation.output;
            },
            
            historic_rehabilitation_tax_impact: function() {
                return this.historic_rehabilitation_total_rehab_cost * this.magic_numbers.historic_rehabilitation.tax;
            },
            
            // Affordable Housing
            
            affordable_housing_total_investment: function() {
                return this.tax_equity_investment_clean * this.magic_numbers.affordable_housing.total_investment_multiplier;
            },
            
            afforable_housing_homes_built: function() {
                return this.affordable_housing_total_investment / this.magic_numbers.affordable_housing.cost_per_unit;
            },
            
            afforable_housing_jobs_created: function() {
                return this.afforable_housing_homes_built * this.magic_numbers.affordable_housing.jobs;
            },
            
            afforable_housing_income_generated: function() {
                return this.afforable_housing_homes_built * this.magic_numbers.affordable_housing.income;
            },
            
            afforable_housing_tax_revenue_generated: function() {
                return this.afforable_housing_homes_built * this.magic_numbers.affordable_housing.tax;
            },

            // Misc

            share_link: function() {
                return window.location.href;
            },

            mailto_string: function() {
               return `mailto:?subject=[Monarch Private Capital] ESG Impact Calculator&body=I wanted to share this ESG Impact Calculator with you: ${this.share_link}`;
            }
        },
        
        filters: {
            round: function(value) {
                if (isNaN(value)) {
                    return 0;
                }
    
                return Math.round(value).toLocaleString();
            }
        }
    });
}