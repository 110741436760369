import './components/calculator';
import './components/contact-form';
import './components/page-header';
import './components/tooltips';
import './components/googleMapsAPI';
import './components/lightbox';
import './components/glide';
import './components/image-slider';
import './components/state-map-app';
import './components/inview';
import './components/ecosystem';

import Slider from './components/slider';
import Accordion from './components/accordion';
import Tablesaw from 'tablesaw';

let sliders = document.querySelectorAll('[data-slider]');

sliders.forEach(slider => {
    let new_slider = new Slider(slider);
});

let accordions = document.querySelectorAll('[data-accordion]');

accordions.forEach(accordion => {
    let new_accordion = new Accordion(accordion);
});

let tables = document.querySelectorAll('#page-content table');

tables.forEach(table => {
    table.classList.add('tablesaw');
    table.dataset.tablesawMode = 'stack';
    Tablesaw.init(table);
});